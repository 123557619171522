let tdUtils;

class TimeDateUtils {

  constructor(prefs) {
      if (typeof prefs === 'undefined') {
          throw new Error('Cannot be called directly')
      }

      this.timezone = prefs.timezone
      if (typeof window.dateFormat !== "undefined") {
        this.dateFormat = window.dateFormat ?? 'DD/MM/YYYY'
      } else {
        this.dateFormat = prefs.dateFormat === 'DEFAULT' ? 'DD/MM/YYYY' : prefs.dateFormat;
      }
      this.dateFormatPattern = this.dateFormat.replace(/-/g, "\-").replace('YYYY', '[0-9]{4,4}').replace('MM', '[0-9]{2,2}').replace('DD', '[0-9]{2,2}')
  }

  /**
   * Get the stored preferences or assign default.
   *
   * `update` would usually be called after this (tdUtils.update())
   */
  static async build() {
      const possiblePrefs = TimeDateUtils.hasStoredPreferences()

      if (possiblePrefs) {
        return new TimeDateUtils(possiblePrefs)
      }

      console.log(new Date().getTimezoneOffset())
      console.log(~(new Date().getTimezoneOffset())+1)

      return new TimeDateUtils({
        timezone: {
          utcSet: 'DEFAULT',
          offset: ~(new Date().getTimezoneOffset())+1
        },
        dateFormat: 'DEFAULT'
      })
  }

  static hasStoredPreferences() {
      try {
          const prefs = window.localStorage.getItem('dateTimePreferences')
          if (!prefs) {
              return null
          }

          return JSON.parse(prefs)
      } catch(e) {
          return null;
      }
  }

  getDateFormat() {
    return this.dateFormat === 'DEFAULT' ? 'DD/MM/YYYY' : this.dateFormat
  }

  /**
   * Update the tdUtils instance with preferences from the server.
   *
   * This was split out to prevent issues with tdUtils not being available on load in some cases.
   */
  async update() {
      const [asyncPrefs] = await $.ajax(`/a/timeDatePreferences`)
      const possiblePrefs = TimeDateUtils.hasStoredPreferences()
      if (
        !asyncPrefs && !possiblePrefs
      ) {
        /**
         * If we have no asynchronous preferences or stored preferences then reset to default.
         */
        localStorage.setItem('dateTimePreferences', JSON.stringify({
          timezone: {
            utcSet: 'DEFAULT',
            offset: ~(new Date().getTimezoneOffset())+1
          },
          dateFormat: 'DEFAULT'
        }))

        this.timezone = {
          utcSet: 'DEFAULT',
          offset: ~(new Date().getTimezoneOffset())+1
        }
        this.dateFormat = 'DEFAULT'
      } else if (
        asyncPrefs && possiblePrefs &&
        (
          JSON.stringify(asyncPrefs.Preferences) !== JSON.stringify(possiblePrefs)
        )
      ) {
        /**
         * If we have async preferences and stored preferences. We compare them to see if we need to update, if don't match then we update.
         */
        localStorage.setItem('dateTimePreferences', JSON.stringify(
          {
            timezone: asyncPrefs.Preferences.timezone,
            dateFormat: asyncPrefs.Preferences.dateFormat
          }
        ))
        this.timezone = asyncPrefs.Preferences.timezone
        this.dateFormat = asyncPrefs.Preferences.dateFormat
      }
  }

  /**
   * If Europe/London is in DST then platform will be.
   *
   * @returns {boolean}
   */
  isServerInDSTNow() {
    // If we are on the dev environment don't check or adjust.
    if (location.hostname !== 'my.20i.com') {
      return false
    }

    return moment.tz(new Date(), 'Europe/London').isDST()
  }

  /**
   * Check if we have a date format set
   */
  hasFormat() {
    return !!(this.dateFormat && this.dateFormat !== 'DEFAULT')
  }

  /**
   * Check if we have a timezone set
   */
  hasTimezone() {
    return !!(this.timezone && this.timezone.utcSet !== 'DEFAULT')
  }

  /**
   * Get a moment object from a date
   *
   * @param {?(string|Date)} date
   */
  getMoment(date) {
    if (!date) {
      return moment(new Date())
    }

    if (isNaN(Date.parse(date))) {
      return false
    }

    let dateToUse
    if (typeof date === 'string') {
      dateToUse = new Date(date)
    } else if (typeof date === 'object') {
      dateToUse = date
    } else {
      dateToUse = new Date()
    }

    return moment(dateToUse)
  }

  /**
   * Revert a moment date to the current UTC time to be sent off to the server
   *
   * @param {any} date Moment date
   */
  revertToUTC(date) {
    return date.utc()
  }

  /**
   * Invert the offset provided by moment-timezone. Used to convert dates before they are sent to the server.
   *
   * @param {any} date Moment date
   */
  invertOffset(date) {
    let utcDate = date.clone().utc()

    if (this.timezone.offset === 0) {
      return utcDate
    }

    return utcDate.utcOffset(this.timezone.offset < 0 ? Math.abs(this.timezone.offset) : -Math.abs(this.timezone.offset))
  }

  /**
   * Get a timezoned UTC moment
   *
   * @param {any} date Moment date to convert to UTC
   * @param {boolean} utc If true, don't offset away from UTC
   */
  getZonedUTCMoment(date, utc) {
    const utcDate = date.utc()

    if (!utc) {
      utcDate.tz(this.timezone.utcSet !== 'DEFAULT' ? this.timezone.utcSet : moment.tz.guess())
    }

    return utcDate
  }

  /**
   * Determine whether to return the moment, format using default formatting
   * or using passed down formatting
   *
   * @param {any} date moment object to format
   * @param {?(string|boolean)} format
   * @param {boolean} time
   */
  formatMoment(date, format, time) {
    if (format === null) {
      return date
    } else if (format === true) {
      return date.format(`${this.dateFormat !== 'DEFAULT' ? this.dateFormat : 'DD/MM/YYYY'}${time ? ', h:mm:ss a' : ''}`)
    }

    return date.format(format)
  }

  formatTimeMoment(date, format) {
    if (format === null) {
      return date
    } else if (format === true) {
      return date.format('H:mm:ss')
    }

    return date.format(format)
  }

  /**
   * Generate a moment date applying TZ and formatting
   *
   * @param {?(string|Date)} date
   * @param {{ format: ?(boolean|string), time: boolean, utc: boolean }} options
   */
  createDate(date = null, options = { format: true, time: false, utc: false }) {
    let initialDate = this.getMoment(date)

    /**
     * If the date is invalid for any reason return the original. This will mean that no formattting or timezone alteration will take place.
     */
    if (!initialDate) {
      if (
        typeof date === 'string' &&
        date.includes('/')
      ) {
        const split = date.split('/')
        initialDate = split.length > 2 ? this.getMoment(
          `${split[2]}-${split[1]}-${split[0]}`
        ) : false
      }

      if (!initialDate) {
        return date
      }
    }

    return this.formatMoment(
      this.getZonedUTCMoment(initialDate, options.utc),
      options.format,
      options.time
    )
  }

  createTime(date = null, options = { format: true }) {
    let initialDate = this.getMoment(date)

    /**
     * If the date is invalid for any reason return the original. This will mean that no formattting or timezone alteration will take place.
     */
    if (!initialDate) {
      return date
    }

    return this.formatTimeMoment(
      this.getZonedUTCMoment(initialDate),
      options.format
    )
  }
}

(async () => {
  tdUtils = await TimeDateUtils.build()
  tdUtils.update()
})()

if(typeof module !== "undefined") {
  module.exports = TimeDateUtils
}
